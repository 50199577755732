const theme = {
  colors: {
    primary: '#caad72',
    secondary: '#0f5955',
    text_footer: '#000000',
    text_primary: '#666666',
    text_dark: '#2b2a29',
    text_quote: '#574d48',
    bg_light: '#f7f7f7',
    white: '#ffffff',
    black: '#000000',
    bg_row_hover: '#f4e4b8',
    state_available: '#55a630',
    state_reserved: '#fb8b24',
    state_sold: '#e01e37',
    transparent: 'transparent',
    // primary: '#e8b867',
    line: 'rgba(43,42,41, 0.15)',
    img_overlay: 'rgba(43,42,41, 0.5)',
    tab_active: '#e5e5e5',
    bg_plan_row: '#e5e5e5',
    bg_footer: '#574d48',
    footer_text: '#cccccc',
    bg_menu_overlay: 'rgba(249,249,249, 0.9)',
    text_list_th: '#747474',
    bg_list_tr: '#f3f2f2',
    error: '#d12d28',
  }
};

export default theme;